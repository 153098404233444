import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/community/house/listData",
        method: "post",
        params: params,
    });
};

export const getLouDongAll = (params) => {
    return axios({
        url: "/api/community/loudong/getLouDongAll",
        method: "get",
        params: params,
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/community/house/addData",
        method: "post",
        params: params,
    });
};

export const delData = (params) => {
    return axios({
        url: "/api/community/house/del",
        method: "post",
        params: params,
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/community/house/editData",
        method: "post",
        params: params,
    });
};