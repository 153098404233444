<template>
  <div>
    <Searchbar @emit-search="onSearch" @reset-form="resetForm">
      <template #SEARCH>
        <el-col :span="6">
          <el-form-item label="小区名称">
            <el-input v-model="searchForm.community_name" placeholder="请输入 小区名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备名称">
            <el-input v-model="searchForm.name" placeholder="请输入 设备名称"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="小区编码">
            <el-input v-model="searchForm.community_code" placeholder="请输入 小区编码"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="设备mac">
            <el-input v-model="searchForm.mac" placeholder="请输入 设备mac"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="安全模块号">
            <el-input v-model="searchForm.cert_sn" placeholder="请输入 设备安全模块号"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="类型">
            <el-select v-model="searchForm.type" filterable placeholder="请选择 类型">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </template>
      <template #BTN>
        <el-button @click="dialogFormVisible = true; title = '新增'" class="searchBtn">新增</el-button>
      </template>
    </Searchbar>

    <basicTable
        @currentChange="handleCurrentChange"
        @sizeChange="handleSizeChange"
        :tableData="tableData"
        :attrList="attrList"
        :page="page.current"
        :limit="page.limit"
        :total="page.total">
    </basicTable>

    <el-dialog
        v-model="dialogFormVisible"
        :title="title"
        :close-on-click-modal="false"
        :before-close="handleClose"
    >
      <div class="addBtn">
        <el-button @click="istype = true">普通用户终端新增</el-button>
        <el-button @click="istype = false">物业用户终端新增</el-button>
      </div>

      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="150px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="community_code" :rules="[{ required: true, message: '请选择 小区名称' }]">
              <el-select @change="changeCommunity" v-model="form.community_code" filterable placeholder="请选择">
                <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="istype">
            <el-form-item label="楼栋号" prop="loudong_code" :rules="[{ required: true, message: '请选择 楼栋号' }]">
              <el-select v-model="form.loudong_code" filterable placeholder="请选择">
                <el-option v-for="item in buildList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="istype">
            <el-form-item label="房屋号" prop="house_code" :rules="[{ required: true, message: '请选择 房屋号' }]">
              <el-select v-model="form.house_code" filterable placeholder="请选择">
                <el-option v-for="item in houseList" :key="item.code" :label="item.code" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备编码" prop="code" :rules="[{ required: true, message: '请获取 设备编码' }]">
              <el-input v-model="form.code" placeholder="请获取 设备编码" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安全模块编号" prop="cert_sn" :rules="[{ required: true, message: '请输入 安全模块编号' }]">
              <el-input v-model="form.cert_sn" placeholder="请输入 安全模块编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="name" :rules="[{ required: true, message: '请输入 设备名称' }]">
              <el-input v-model="form.name" placeholder="请输入 设备名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="mac编码" prop="mac" :rules="[{ required: true, message: '请输入 mac编码' }]">
              <el-input v-model="form.mac" placeholder="请输入 mac编码"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经纬度" prop="latitude">
              <Getnpoint ref="point" @SET_POINT="setpoint" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="onSave">提交</el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog
        append-to-body
        v-model="editFormVisible"
        title="编辑"
        :close-on-click-modal="false"
        @open="handleOpen"
        :before-close="handleClose"
    >
      <el-form
          label-position="right"
          :model="form"
          size="small"
          label-width="150px"
          ref="dataform"
      >
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="小区名称" prop="community_code" :rules="[{ required: true, message: '请选择 小区名称' }]">
              <el-select @change="editCommunity" v-model="form.community_code" filterable placeholder="请选择">
                <el-option v-for="item in communityList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="istype">
            <el-form-item label="楼栋号" prop="loudong_code" :rules="[{ required: true, message: '请选择 楼栋号' }]">
              <el-select v-model="form.loudong_code" filterable placeholder="请选择">
                <el-option v-for="item in buildList" :key="item.code" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="istype">
            <el-form-item label="房屋号" prop="house_code" :rules="[{ required: true, message: '请选择 房屋号' }]">
              <el-select v-model="form.house_code" filterable placeholder="请选择">
                <el-option v-for="item in houseList" :key="item.code" :label="item.code" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="设备编码" prop="code" :rules="[{ required: true, message: '请获取 设备编码' }]">
              <el-input v-model="form.code" placeholder="请获取 设备编码" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="安全模块编号" prop="cert_sn" :rules="[{ required: true, message: '请输入 安全模块编号' }]">
              <el-input v-model="form.cert_sn" placeholder="请输入 安全模块编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="设备名称" prop="name" :rules="[{ required: true, message: '请输入 设备名称' }]">
              <el-input v-model="form.name" placeholder="请输入 设备名称"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="mac编码" prop="mac" :rules="[{ required: true, message: '请输入 mac编码' }]">
              <el-input v-model="form.mac" placeholder="请输入 mac编码"></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="经纬度" prop="latitude">
              <Getnpoint ref="editpoint" @SET_POINT="setpoint" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" size="small" @click="editSave">提交</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import { dataList, addData, editData, delData, houseAll, createResourceNumber, addPropertyData, editPropertyData } from "@/api/popularization/hometerminals";
import { getLouDongAll } from "@/api/popularization/house";
import { communityAll } from "@/api/popularization/build";
import Searchbar from '@/components/Searchbar';
import Getnpoint from '@/components/Getnpoint'
import { ElMessage, ElMessageBox } from "element-plus";
export default {
  components:{
    Searchbar, Getnpoint
  },
  data(){
    return{
      options:[
        {
          value: '1',
          label: '普通用户终端',
        },
        {
          value: '2',
          label: '物业终端',
        }
      ],
      istype:true,
      houseList:[],
      buildList:[],
      communityList:[],
      editFormVisible:false,
      title:"",
      dialogFormVisible: false,
      form:{},
      searchForm:{},
      tableData:[],
      attrList:[
        {
          prop: "code",
          label: "资源编码",
          render: (row) => <div>{row.code}</div>,
        },
        {
          prop: "name",
          label: "名称",
          render: (row) => <div>{row.name}</div>,
        },
        {
          prop: "mac",
          label: "mac地址",
          render: (row) => <div>{row.mac}</div>,
        },
        {
          prop: "community_code",
          label: "小区编码",
          render: (row) => <div>{row.community_code}</div>,
        },
        {
          prop: "community_name",
          label: "小区名称",
          render: (row) => <div>{row.community_name}</div>,
        },
        {
          prop: "loudong_code",
          label: "楼栋编码",
          render: (row) => <div>{row.loudong_code}</div>,
        },
        {
          prop: "house_code",
          label: "房号",
          render: (row) => <div>{row.house_code}</div>,
        },
        {
          prop: "type",
          label: "类型",
          render: (row) => <div>{ row.type == 1 ? "普通终端" : "管理终端" }</div>,
        },
        {
          prop: "",
          label: "编辑",
          render: (row) => (
              <div>
                <el-button type="text" icon="Edit" onClick={()=>this.dataEdit(row)}>
                  编辑
                </el-button>
                <el-button type="text" icon="Delete" onClick={()=>this.del(row)}>
                  删除
                </el-button>
              </div>
          )
        },
      ],
      page: {
        current: 1,
        limit: 10,
        total: 0,
      },
    }
  },
  watch:{
    // "form.community_code"(n,o){
    //
    // },
    "form.loudong_code"(n,o){
      if(!n) return;
      houseAll({
        community_code: this.form.community_code,
        loudong_code:n
      }).then(res=>{
        this.houseList = res.data.data;
      })
    }
  },
  created() {
    this.onLoad();
    communityAll().then(res=>{
      this.communityList = res.data.data;
    })
  },
  methods:{
    setpoint(val){
      let point = val.split(',');
      this.form.longitude = point[0];
      this.form.latitude = point[1];
    },

    editCommunity(n){
      if(!n) return;
      getLouDongAll({ community_code: n}).then(res=>{
        this.buildList = res.data.data;
      })
    },
    changeCommunity(n){
      if(!n) return;
      getLouDongAll({ community_code: n}).then(res=>{
        this.buildList = res.data.data;
      })
      createResourceNumber({
        community_code:n
      }).then(res=>{
        this.form.code = res.data.data;
      })
    },

    handleOpen(){
      getLouDongAll({ community_code: this.form.community_code}).then(res=>{
        this.buildList = res.data.data;
      })
    },

    handleClose(done){
      done();
      this.$nextTick(()=>{
        this.form = {};
        this.$refs['dataform'].resetFields();
      })
    },

    handleCurrentChange(currentPage) {
      this.page.current = currentPage;
      this.onLoad();
    },

    handleSizeChange(pageSize) {
      this.page.limit = pageSize;
      this.onLoad();
    },

    onSearch(){
      this.page.current = 1;
      this.onLoad();
    },

    resetForm() {
      this.searchForm = {};
      this.onLoad();
    },

    del(row){
      ElMessageBox.confirm(
          '此操作将永久删除该数据, 是否继续?',
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
      ).then(() => {
        delData({id:row.id}).then(res=>{
          ElMessage({message: res.data.code.message, type: 'success'});
          this.page.current = 1;
          this.page.limit = 10;
          this.searchForm = {};
          this.onLoad();
        })
      }).catch(() => {})
    },
    editSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let {id, name, code, mac, cert_sn, community_code, loudong_code, house_code, longitude, latitude } = this.form;
          let community_name = this.communityList.filter(item=>item.code == community_code)[0].name;
          let loudong_name = "";
          if(this.istype){
            loudong_name = this.buildList.filter(item=>item.code == loudong_code)[0].name;
            editData({
              id, name, code, mac, cert_sn, house_code, longitude, latitude, community_code, loudong_code, community_name, loudong_name
            }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.editFormVisible = false;
                this.$nextTick(()=>{
                  this.form = {};
                  this.$refs['dataform'].resetFields();
                })
                this.onLoad();
              }
            })
          }else {
            editPropertyData({ id, name, code, mac, cert_sn, longitude, latitude, community_code, community_name, type:2 }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.editFormVisible = false;
                this.$nextTick(()=>{
                  this.form = {};
                  this.$refs['dataform'].resetFields();
                })
                this.onLoad();
              }
            })
          }
        } else {
          return false;
        }
      });
    },

    dataEdit(row){
      row.type == 1 ? this.istype = true : this.istype = false;
      this.form = JSON.parse(JSON.stringify(row));
      this.editFormVisible = true;
      this.$nextTick(()=>{
        if(this.form.longitude) this.$refs['editpoint'].points = `${this.form.longitude},${this.form.latitude}`;
      })
    },
    onSave(){
      this.$refs.dataform.validate((valid) => {
        if (valid) {
          let { name, code, mac, cert_sn, community_code, loudong_code, house_code, longitude, latitude } = this.form;
          let community_name = this.communityList.filter(item=>item.code == community_code)[0].name;
          let loudong_name = "";
          if(this.istype){
            loudong_name = this.buildList.filter(item=>item.code == loudong_code)[0].name;
            addData({
              name, code, mac, cert_sn, house_code, longitude, latitude, community_code, loudong_code, community_name, loudong_name
            }).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.dialogFormVisible = false;
                this.page.current = 1;
                this.page.limit = 10;
                this.searchForm = {};
                this.$nextTick(()=>{
                  this.form = {};
                  this.$refs['dataform'].resetFields();
                })
                this.onLoad();
              }
            })
          }else{
            addPropertyData({name, code, mac, cert_sn, longitude, latitude, community_code, community_name, type:2}).then(res=>{
              if(res.data.success){
                ElMessage({message: res.data.code.message, type: 'success'});
                this.dialogFormVisible = false;
                this.page.current = 1;
                this.page.limit = 10;
                this.searchForm = {};
                this.$nextTick(()=>{
                  this.form = {};
                  this.$refs['dataform'].resetFields();
                })
                this.onLoad();
              }
            })
          }
        } else {
          return false;
        }
      });
    },
    onLoad(){
      dataList({ limit:this.page.limit, page:this.page.current, ...this.searchForm }).then(res=>{
        this.tableData = res.data.data.list;
        this.page.total = res.data.data.count;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.addBtn{
  display: flex;
  margin-bottom: 20px;
}
</style>