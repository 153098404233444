import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/community/resource/listData",
        method: "post",
        params: params,
    });
};


export const houseAll = (params) => {
    return axios({
        url: "/api/community/house/houseAll",
        method: "post",
        params: params,
    });
};


export const addData = (params) => {
    return axios({
        url: "/api/community/resource/addData",
        method: "post",
        params: params,
    });
};


export const addPropertyData = (params) => {
    return axios({
        url: "/api/community/resource/addPropertyData",
        method: "post",
        params: params,
    });
};


export const createResourceNumber = (params) => {
    return axios({
        url: "/api/community/resource/createResourceNumber",
        method: "post",
        params: params,
    });
};

export const delData = (params) => {
    return axios({
        url: "/api/community/resource/del",
        method: "post",
        params: params,
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/community/resource/editData",
        method: "post",
        params: params,
    });
};

export const editPropertyData = (params) => {
    return axios({
        url: "/api/community/resource/editPropertyData",
        method: "post",
        params: params,
    });
};

