import axios from "@/api/axios"; // 引用axios

export const dataList = (params) => {
    return axios({
        url: "/api/community/loudong/listData",
        method: "post",
        params: params,
    });
};

export const addData = (params) => {
    return axios({
        url: "/api/community/loudong/addData",
        method: "post",
        params: params,
    });
};

export const editData = (params) => {
    return axios({
        url: "/api/community/loudong/editData",
        method: "post",
        params: params,
    });
};

export const delData = (params) => {
    return axios({
        url: "/api/community/loudong/del",
        method: "post",
        params: params,
    });
};

export const communityAll = (params) => {
    return axios({
        url: "/api/community/all",
        method: "get",
        params: params,
    });
};

export const getCommunityResourceAll = (params) => {
    return axios({
        url: "/api/community/resource/getCommunityResourceAll",
        method: "post",
        params: params,
    });
};


export const bindingResource = (params) => {
    return axios({
        url: "/api/community/loudong/bindingResource",
        method: "get",
        params: params,
    });
};

